/* $typography
 ------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  color: $headings-color;
  font-weight: normal;
  margin-top: 0;
}



// h5,
// .h5 {
//   font-size: $font-size-h5;
//   color: $h5-font-color;
//   font-weight: $headings-font-weight;
// }

// h6,
// .h6 {
//   font-size: $font-size-h6;
//   color: $h6-font-color;
//   font-weight: $headings-font-weight;
// }

a {
  text-decoration: none;
  color: inherit;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}



strong,
b {
  font-weight: 700;
}
